export type UserFormData = {
  givenName: string
  familyName: string
  email: string
  sampleDate: string
  containerCode: string
  nationalIdentityNumber: string
  dateOfBirth: string
  gender: string
}

export type Consent = {
  primary: boolean
  secondary: boolean
}

export interface UserStoreState<T> {
  error?: string | null
  loading?: boolean | null
  user: T
  consent?: Consent | null
  requestId?: string | null
}

export enum UserActionTypes {
  SET_USER_ERROR = 'SET_USER_ERROR',
  SET_DIAGNOSTIC_REQUEST_ID = 'SET_DIAGNOSTIC_REQUEST_ID',
  SET_USER_EMAIL = 'SET_USER_EMAIL',
  SET_USER_INFO = 'SET_USER_INFO',
  SET_USER_INPUTS = 'SET_USER_INPUTS',
  CAPTURE_CONSENT = 'CAPTURE_CONSENT',
  CLEAR_USER = 'CLEAR_USER',
}
