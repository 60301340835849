import { Fragment } from 'react'

export function getHighlightedText(text: string, searchValue: string, highlightedColor: string) {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text?.split(new RegExp(`(${searchValue})`, 'gi'))
  return parts?.map((part, index) => (
    <Fragment key={index}>
      {part?.toLowerCase() === searchValue?.toLowerCase() ? (
        <b style={{ color: highlightedColor, fontWeight: 800 }}>{part}</b>
      ) : (
        part
      )}
    </Fragment>
  ))
}
