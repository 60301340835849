import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { routes } from '@/route.types'
import { StyledContainer } from './layout-with-outlet.styled'
import {useTranslation} from "react-i18next";

export const LayoutWithOutlet = () => {
  const location = useLocation();
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('common.heading', {ns: 'authenticate'});
    if (location.pathname === routes.Home) navigate(routes.Login);
  }, [location.pathname, navigate])

  return (
    <StyledContainer component="main">
      <Outlet />
    </StyledContainer>
  )
}
