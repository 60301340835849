import { Typography } from '@mui/material'
import { TableNoDataProps } from './table.interface'
import { StyledNoTableData } from './table.styled'

export const NoTableData = ({ message }: TableNoDataProps) => (
  <tr>
    <StyledNoTableData colSpan={999}>
      <Typography textAlign="center">{message}</Typography>
    </StyledNoTableData>
  </tr>
)
