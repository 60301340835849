import { styled } from '@infra/ui-design-system'
import { Box, ContainerProps } from '@mui/material'

export const StyledContainer = styled(Box)<ContainerProps & { component: string }>(
  ({ theme }) => `
    flex: 1;
    padding: 0 ${theme.spacing(3)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;

    ${theme.breakpoints.up('sm')} {
      padding: 0 ${theme.spacing(5)};
    }

    ${theme.breakpoints.up('md')} {
      padding: 0 ${theme.spacing(7)};
  `
)
