import { TableCell } from '@mui/material'
import { SkeletonBlockItem } from '@infra/ui-design-system'
import { TableSkeletonProps } from './table.interface'
import { StyledRow } from './table.styled'

const COUNT_DEFAULT = 10

export const TableSkeleton = ({ count = COUNT_DEFAULT, columns }: TableSkeletonProps) => {
  const SkeletonArray = Array(count).fill('')
  return (
    <>
      {SkeletonArray.map((_, index) => (
        <StyledRow key={`${index}-skeleton-item`}>
          {columns.map(column => (
            <TableCell key={`skeleton-item-${column.id}`} data-test="table-value">
              <SkeletonBlockItem height="30px" />
            </TableCell>
          ))}
        </StyledRow>
      ))}
    </>
  )
}
