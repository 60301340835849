import { styled } from '@infra/ui-design-system'
import { Navigate, Route, Routes, Redirect } from 'react-router-dom'
import { routes as LazyRoutes } from './lazy-routes'
import { routes } from './route.types'

const StyledRoutes = styled(Routes)`
  padding: 0;
`
export const RoutesProvider = () => {
  return (
    <StyledRoutes>
      <Route path={routes.Home} element={<LazyRoutes.LayoutWithOutlet />}>
        <Route path={routes.SetPassword.replace('/auth/', '')} element={<Navigate to={`${routes.SetPassword}${window.location.search}`} />} />
        {/* Email / Password authentication with firebase / soon to be api-gateway */}
        <Route path={routes.Login} element={<LazyRoutes.Login />} />
        <Route path={routes.Signup} element={<LazyRoutes.Signup />} />
        <Route path={routes.SetAuthCode} element={<LazyRoutes.SetAuthCode />} />
        <Route path={routes.ResetPassword} element={<LazyRoutes.ResetPassword />} />
        <Route path={routes.SetPassword} element={<LazyRoutes.SetPassword />} />
        <Route path={routes.CheckYourInbox} element={<LazyRoutes.CheckYourInbox />} />
        <Route path={routes.Support} element={<LazyRoutes.Support />} />
        {/* DiagnosticReports screen */}
        <Route
          path={routes.DiagnosticReports}
          element={<LazyRoutes.ProtectedRoute component={() => <LazyRoutes.DiagnosticReports />} />}
        />

        <Route
          path={`${routes.DiagnosticReportStatic}/:type/:id`}
          element={
            <LazyRoutes.ProtectedRoute component={() => <LazyRoutes.DiagnosticReportStatic />} />
          }
        />

        {/* QRCode screen */}
        <Route
          path={routes.CreateQrCode}
          element={<LazyRoutes.ProtectedRoute component={() => <LazyRoutes.CreateQrCode />} />}
        />

        <Route path={routes.Error} element={<LazyRoutes.Error />} />

        <Route path={routes.Four0Four} element={<LazyRoutes.NoneFound />} />

        <Route path={routes.CatchAll} element={<Navigate to={routes.Error} replace />} />
      </Route>
    </StyledRoutes>
  )
}
