import { Shell, userStore, UserState, UserActionTypes, Action } from '@infra/application-shell'
import { psDoctorsTheme } from '@infra/ui-design-system'
import {FC, ReactElement, useEffect} from 'react'
import { BrowserRouter } from 'react-router-dom'
import { i18n } from './i18n'
import Smartlook from 'smartlook-client'
import {useUmamiTag} from "@infra/helpers";


export const storeReducer = (state: UserState, action: Action<UserActionTypes>) => {
  switch (action.type) {
    case UserActionTypes.SET_USER_ERROR:
      return { ...state, error: action.payload }
    case UserActionTypes.SET_USER_INFO:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      }
    case UserActionTypes.CLEAR_USER:
      return {
        ...state,
        user: {},
      }
    default:
      return state
  }
}

const userStoreCreate = userStore(storeReducer)

interface AppShellProps {
  children: NonNullable<ReactElement>
}

const AppShell: FC<AppShellProps> = ({ children }) => {

  useUmamiTag(import.meta.env.VITE_UMAMI_WEBSITEID || null);

  useEffect(() => {
    Smartlook.init(import.meta.env.VITE_SMARTLOOK_API_KEY, { region: 'eu', cookies: false });
  }, [])

  return (
    <BrowserRouter>
      <Shell theme={psDoctorsTheme} i18n={i18n} userStoreCreate={userStoreCreate}>
        {children}
      </Shell>
    </BrowserRouter>
  )
}

export { AppShell, userStoreCreate }
