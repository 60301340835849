import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@infra/ui-design-system'
import { LayoutWrapper } from '@infra/common-components'
import { ReactElement, ReactNode } from 'react'

interface ViewportProps {
  theme: Omit<ThemeOptions, 'mixins'>
  children: NonNullable<ReactNode | ReactElement>
}

export default function Viewport({ children, theme }: ViewportProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LayoutWrapper>{children}</LayoutWrapper>
    </ThemeProvider>
  )
}
