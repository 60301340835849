import { LayoutWrapper } from '@infra/common-components'
import { lazy } from 'react'
import { LayoutWithOutlet } from '@/shared/router/layout-with-outlet'
import { ProtectedRoute } from './shared/router/protected-route'

const Login = lazy(() => import('./pages/authentication/login'))
const Signup = lazy(() => import('./pages/authentication/Signup'))
const SetAuthCode = lazy(() => import('./pages/authentication/set-auth-code'))
const ResetPassword = lazy(() => import('./pages/authentication/reset-password'))
const SetPassword = lazy(() => import('./pages/authentication/set-password'))
const CheckYourInbox = lazy(() => import('./pages/authentication/CheckYourInbox'))
const Support = lazy(() => import('./pages/support'))
const DiagnosticReports = lazy(() => import('./pages/diagnostic-reports'))
const DiagnosticReportStatic = lazy(() => import('./pages/diagnostic-report-static'))
const DiagnosticReportDetail = lazy(() => import('./pages/diagnostic-report-detail'))
const ConfirmRequest = lazy(() => import('./pages/confirm-request'))
const ShareDiagnosticRequest = lazy(() => import('./pages/share-diagnostic-request'))
const CreateQrCode = lazy(() => import('./pages/create-qr-code'))
const Error = lazy(() => import('./pages/error'))
const NoneFound = lazy(() => import('./pages/wrong-route'))

export const routes = {
  Login,
  Signup,
  CheckYourInbox,
  SetAuthCode,
  ResetPassword,
  SetPassword,
  DiagnosticReportDetail,
  DiagnosticReportStatic,
  ConfirmRequest,
  ShareDiagnosticRequest,
  DiagnosticReports,
  CreateQrCode,
  Support,
  Error,
  NoneFound,
  ProtectedRoute,
  LayoutWrapper,
  LayoutWithOutlet,
}
