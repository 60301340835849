import {useEffect} from "react";

export const useUmamiTag = (websiteId: string|null) => {
  useEffect(() =>
  {
    if(!document || !websiteId) return;

    const script = document.createElement('script');
    script.async = true;
    script.id = 'daslab-umami';
    script.src = 'https://analytics.daslab.health/script.js';
    script.setAttribute('data-website-id', websiteId);
    script.type = 'text/javascript';
    document.body.appendChild(script);

    return () => {document.body.removeChild(script)};
  }, [websiteId, document]);
};
