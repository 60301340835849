import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthState, getAuth, app } from '@infra/firebase'
import { routes } from '@/route.types'

export const ProtectedRoute = ({ component }: PropsWithChildren<any>) => {
  const [user, loading, error] = useAuthState(getAuth(app))

  console.log('>> logging', { user, error })

  if (loading) {
    return null
  }

  if ((!user || error) && !loading) {
    return <Navigate to={routes.Login} />
  }

  const Component = component
  return <Component />
}
