import { TableCell as MuiTableCell } from '@mui/material'
import { useEffect, useRef, useState, isValidElement } from 'react'
import { SkeletonBlockItem } from '@infra/ui-design-system'
import { getHighlightedText } from '@infra/helpers'
import { TableCellProps } from './table.interface'
import { StyledTableCellContent } from './table.styled'
import {width} from "@mui/system";

export const TableCell = <T,>({
  loading,
  column,
  row,
  shouldHighlight,
  isMobile,
  searchValue,
  highlightedColor,
}: TableCellProps<T>) => {
  const [isTitleDisplayed, setIsTitleDisplayed] = useState(false)
  const cellRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (cellRef.current) {
      const compare = cellRef.current.scrollWidth > cellRef.current.clientWidth
      setIsTitleDisplayed(compare)
    }
  }, [])

  return (
    <MuiTableCell
      data-test="table-value"
      className={isValidElement(row[column?.id]) ? 'no-padding' : ''}
    >
      <StyledTableCellContent
        {...(isTitleDisplayed
          ? {
              title: row[column?.id],
            }
          : {})}
        wi={column.width}
        mw={column.maxWidth}
        isMobile={isMobile}
        ref={cellRef}
      >
        {!loading ? (
          shouldHighlight ? (
            getHighlightedText(row[column?.id], searchValue, highlightedColor)
          ) : (
            row[column?.id]
          )
        ) : (
          <SkeletonBlockItem height="30px" />
        )}
      </StyledTableCellContent>
    </MuiTableCell>
  )
}
