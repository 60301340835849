const Home = '/'

const Login = '/auth/login'
const Support = '/help'
const Signup = '/auth/signup'
const CheckYourInbox = '/auth/check-your-inbox'
const ForgotPassword = '/auth/forgot-password'
const ResetPassword = '/auth/reset-password'
const SetPassword = '/auth/set-password'
const SetAuthCode = '/auth/authenticate'

const DiagnosticReportDetail = '/diagnostic-report/:id'
const DiagnosticReports = '/diagnostic-reports'
const DiagnosticReportStatic = '/diagnostic-report-detail'

const CreateDiagnosticRequest = '/st/create-diagnostic-request'
const ConfirmRequest = '/st/confirm-diagnostic-request'
const ShareDiagnosticRequest = '/st/share-diagnostic-request'

const CreateQrCode = '/create-qr-code'
const StepperParent = '/st'

const Error = '/error'
const Four0Four = '/404'
const CatchAll = '*'

export const routes: Record<string, string> = {
  Home,
  Login,
  Signup,
  CheckYourInbox,
  Support,

  ForgotPassword,
  ResetPassword,
  SetPassword,
  SetAuthCode,

  DiagnosticReports,
  StepperParent,
  DiagnosticReportDetail,
  DiagnosticReportStatic,
  CreateDiagnosticRequest,
  ConfirmRequest,
  ShareDiagnosticRequest,
  CreateQrCode,
  Error,
  Four0Four,
  CatchAll,
}
