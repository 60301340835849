import { Store } from '@infra/state-stores'
import { i18n } from 'i18next'
import { ReactElement, ReactNode } from 'react'
import { I18nextProvider } from 'react-i18next'
import { UserActionTypes, UserState, UserStoreProvider } from '../store'
import Viewport from './viewport'

interface ShellProps {
  children: NonNullable<ReactNode | ReactElement>
  userStoreCreate: Store<UserState, UserActionTypes>
  i18n: i18n
  theme: Omit<ThemeOptions, 'mixins'>
}

export function Shell({ children, userStoreCreate, i18n, theme }: ShellProps) {
  return (
    <I18nextProvider i18n={i18n}>
      <UserStoreProvider userStoreCreate={userStoreCreate}>
        <Viewport theme={theme}>{children}</Viewport>
      </UserStoreProvider>
    </I18nextProvider>
  )
}
