import { InitOptions } from 'i18next'
import { getLanguageCodeFromBrowser } from '@infra/helpers'

export const supportedLanguages = ['en', 'es'];
export const i18nextConfig: InitOptions = {
  debug: false,
  load: 'languageOnly',
  fallbackLng: 'en_GB',
  lng: getLanguageCodeFromBrowser(supportedLanguages),
  ns: [
    'authenticate',
    'diagnostic-requests',
    'diagnostic-results',
    // 'patient-information',
    // 'diagnostic-results-detail',
    'diagnostic-request-parameters',
    'confirm-request',
    'share-request',
    'shared/common',
    'shared/error',
  ],
  defaultNS: 'authenticate',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: false,
  },
  initImmediate: false,
  parseMissingKeyHandler: () => '',
}
