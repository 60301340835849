import { Global, css } from '@infra/ui-design-system'

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        main {padding: 0 !important;}
        body.bg-white {background: white}
      `}
    />
  )
}

export default GlobalStyles
